
	import http from "../../../../common/services/http";
	import {
		defineComponent,
		computed,
		ref,
		reactive,
		onMounted,
		watch,
	} from "vue";
	import moment from "moment";
	import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const form = ref({});

			const farm_id = ref(0);
			const farms = ref([]);

			const varieties = ref({
				data: [],
			});

			const processing = ref(false);

			const starting = ref(new Date());
			const masks = ref({
				input: "YYYY-MM-DD",
			});

			function fD(d: any, f = "PPPP") {
				return format(d, f);
			}

			function fetchFarms() {
				processing.value = true;
				http.get(`/api/farms?fetch=all`).then((res) => {
					farms.value = res.data;
					processing.value = false;
				});
			}

			function getDates(start: any) {
				const stop = new Date(
					new Date(start).getTime() + 69 * 24 * 60 * 60 * 1000
				);
				const dates = [];
				let currentDate = moment(start);
				const stopDate = moment(stop);
				while (currentDate <= stopDate) {
					dates.push(moment(currentDate).format("YYYY-MM-DD"));
					currentDate = moment(currentDate).add(1, "days");
				}

				return dates;
			}

			const weeks = computed(() => {
				const dates = getDates(starting.value);
				const size = 7;

				const result = dates.reduce((group: any, item, index) => {
					const c = Math.floor(index / size);

					if (!group[c]) {
						group[c] = [];
					}

					group[c].push(item);

					return group;
				}, []);

				return result;
			});

			async function fetchPicks() {
				const res = await http.get(
					`api/forecasts?start=${fD(
						new Date(weeks.value[0][0]),
						"yyy-MM-dd"
					)}&end=${fD(new Date(weeks.value[9][6]), "yyy-MM-dd")}`
				);

				return res.data.data;
			}

			function savePick() {
				http.post("/api/forecasts", {
					forecasts: form.value,
					start: fD(new Date(weeks.value[0][0]), "yyy-MM-dd"),
					end: fD(new Date(weeks.value[9][6]), "yyy-MM-dd"),
				}).then((res) => {
					fetchPicks();
				});
			}

			function fetchForecast() {
				processing.value = true;

				fetchPicks().then((fs: any) => {
					http.get(`/api/varieties?fetch=all`).then((res) => {
						const a = res.data;
						varieties.value = a;

						form.value = weeks.value.reduce((l: any, w: any) => {
							const p: any = fs.find(
								(or: any) => or.date == w[0]
							);

							return {
								...l,
								[w[0]]: a.reduce(
									(r: any, v: any) => ({
										...r,
										[v.id]:
											(p?.quantities as any)[v.id] | 0,
									}),
									{}
								),
							};
						}, {});

						processing.value = false;
					});
				});
			}

			watch(
				() => starting.value,
				() => {
					fetchForecast();
				}
			);

			onMounted(() => {
				fetchForecast();
			});

			return {
				fetchFarms,
				fetchForecast,
				processing,
				varieties,
				fD,
				starting,
				masks,
				getDates,
				form,
				savePick,
				weeks,
				farms,
				farm_id,
			};
		},
	});
