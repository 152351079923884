<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-md">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Dashboard</span>
				</router-link>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ $route.meta.title }}
					</h2>
					<div class="ng-block-des d-print-none">
						<p>
							{{ fD(new Date(weeks[0][0])) }} -
							{{ fD(new Date(weeks[9][6])) }}
						</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<a
								href="#newItem"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-calendar"></em>
								<span>
									<span class="d-none d-sm-inline-block">
										Start
									</span>
									Date
								</span>
							</a>
							<div class="modal fade" tabindex="-1" id="newItem">
								<div class="modal-dialog" role="document">
									<div
										class="modal-content justify-content-center"
									>
										<button
											id="hideNewForm"
											class="close"
											data-dismiss="modal"
											aria-label="Close"
										>
											<em
												class="icon ni ni-cross text-white"
											></em>
										</button>

										<div class="modal-header bg-primary">
											<h5 class="modal-title text-white">
												Set Start Date
											</h5>
										</div>

										<div
											class="modal-body row justify-content-center"
										>
											<div
												class="col-md-7 justify-content-center text-center mb-1"
											>
												<v-date-picker
													v-model="starting"
												/>
											</div>
											<div class="col-md-5 text-center">
												<h5>Starting on</h5>
												<p>
													{{ fD(starting) }}
												</p>

												<button
													class="btn btn-outline-primary float-right"
													data-dismiss="modal"
												>
													<span>
														Use Selected Date
													</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<form class="card card-bordered loading" v-if="processing">
				<div class="justify-content-center text-center">
					<div class="spinner-grow text-primary m-3" role="status">
						<span class="sr-only">Processing Data...</span>
					</div>
					<h6 class="mb-4">Processing Data...</h6>
				</div>
			</form>

			<form
				@submit.prevent="savePick"
				class="card pmd-table-card"
				:class="{ 'card-bordered': varieties.length }"
				v-else
			>
				<table
					class="table table-tranx pmd-table"
					v-if="varieties.length"
				>
					<thead class="tb-tnx-head">
						<tr class="tb-tnx-item">
							<th class="tb-tnx-info">
								<!-- <input
									type="text"
									class="form-control form-control-sm border-0 d-print-none"
									placeholder="Search Product"
									v-model="search"
									@keyup="searchVarieties"
								/> -->
							</th>
							<th
								v-for="(week, i) in weeks"
								:key="i"
								class="text-center align-middle"
							>
								Wk
								{{ fD(new Date(week[0]), "ww") }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							class="tb-tnx-item"
							v-for="p in varieties"
							:key="p.id"
						>
							<td class="tb-tnx-info align-middle">
								<div class="tb-tnx-desc">
									<span class="title">
										{{ p.name }}
									</span>
								</div>
							</td>

							<td v-for="(week, i) in weeks" :key="i" :data-title="`Wk ${fD(new Date(week[0]), 'ww') }`">
								<template v-for="(v, d) in form" :key="d">
									<input
										v-if="d == week[0]"
										class="form-control form-control-sm border-0"
										type="number"
										v-model="v[p.id]"
									/>
								</template>
							</td>
						</tr>
					</tbody>
				</table>

				<table v-else>
					<tr>
						<td colspan="11">
							<div class="alert alert-warning text-center">
								<h5>
									<em
										class="icon ni ni-alert text-warning"
									></em>
								</h5>

								Please select a farm to set pick forecasts for.
							</div>
						</td>
					</tr>
				</table>
			</form>
		</div>

		<div class="ng-block d-print-none">
			<button
				@click.prevent="savePick"
				class="btn btn-primary float-right"
			>
				Save
				<span class="pl-1 d-none d-sm-block">
					Projections
				</span>
			</button>
		</div>
	</div>
</template>

<script lang="ts">
	import http from "../../../../common/services/http";
	import {
		defineComponent,
		computed,
		ref,
		reactive,
		onMounted,
		watch,
	} from "vue";
	import moment from "moment";
	import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const form = ref({});

			const farm_id = ref(0);
			const farms = ref([]);

			const varieties = ref({
				data: [],
			});

			const processing = ref(false);

			const starting = ref(new Date());
			const masks = ref({
				input: "YYYY-MM-DD",
			});

			function fD(d: any, f = "PPPP") {
				return format(d, f);
			}

			function fetchFarms() {
				processing.value = true;
				http.get(`/api/farms?fetch=all`).then((res) => {
					farms.value = res.data;
					processing.value = false;
				});
			}

			function getDates(start: any) {
				const stop = new Date(
					new Date(start).getTime() + 69 * 24 * 60 * 60 * 1000
				);
				const dates = [];
				let currentDate = moment(start);
				const stopDate = moment(stop);
				while (currentDate <= stopDate) {
					dates.push(moment(currentDate).format("YYYY-MM-DD"));
					currentDate = moment(currentDate).add(1, "days");
				}

				return dates;
			}

			const weeks = computed(() => {
				const dates = getDates(starting.value);
				const size = 7;

				const result = dates.reduce((group: any, item, index) => {
					const c = Math.floor(index / size);

					if (!group[c]) {
						group[c] = [];
					}

					group[c].push(item);

					return group;
				}, []);

				return result;
			});

			async function fetchPicks() {
				const res = await http.get(
					`api/forecasts?start=${fD(
						new Date(weeks.value[0][0]),
						"yyy-MM-dd"
					)}&end=${fD(new Date(weeks.value[9][6]), "yyy-MM-dd")}`
				);

				return res.data.data;
			}

			function savePick() {
				http.post("/api/forecasts", {
					forecasts: form.value,
					start: fD(new Date(weeks.value[0][0]), "yyy-MM-dd"),
					end: fD(new Date(weeks.value[9][6]), "yyy-MM-dd"),
				}).then((res) => {
					fetchPicks();
				});
			}

			function fetchForecast() {
				processing.value = true;

				fetchPicks().then((fs: any) => {
					http.get(`/api/varieties?fetch=all`).then((res) => {
						const a = res.data;
						varieties.value = a;

						form.value = weeks.value.reduce((l: any, w: any) => {
							const p: any = fs.find(
								(or: any) => or.date == w[0]
							);

							return {
								...l,
								[w[0]]: a.reduce(
									(r: any, v: any) => ({
										...r,
										[v.id]:
											(p?.quantities as any)[v.id] | 0,
									}),
									{}
								),
							};
						}, {});

						processing.value = false;
					});
				});
			}

			watch(
				() => starting.value,
				() => {
					fetchForecast();
				}
			);

			onMounted(() => {
				fetchForecast();
			});

			return {
				fetchFarms,
				fetchForecast,
				processing,
				varieties,
				fD,
				starting,
				masks,
				getDates,
				form,
				savePick,
				weeks,
				farms,
				farm_id,
			};
		},
	});
</script>
